import { $qs, $qsa } from '../utils/QuerySelector';

class Menu {

    constructor(topLevelMenu, menuTriggerButton) {
        this.topLevelMenu = topLevelMenu;
        this.menuTriggerButton = menuTriggerButton;
        this.mobileHeader = $qs('.mobile-header');
        this.state = 'closed';
        this.bodyElement = $qs('body');

        // Events
        this.menuTriggerButton.addEventListener('click', () => {
            this.toggleMenu();
        }, false);
    }
    
    updateState(state) {
        this.state = state;
    }
    
    toggleMenu() {
        
        if (this.state === 'open') {
            this.closeMenu();
        } else {
            this.openMenu();
        }
    }

    openMenu() {
        this.menuTriggerButton.classList.add('open');
        this.topLevelMenu.classList.add('open');
        this.mobileHeader.classList.add('open');
        this.bodyElement.style.overflow = 'hidden';
        
        this.updateState('open');
    }

    closeMenu() {
        this.menuTriggerButton.classList.remove('open');
        this.topLevelMenu.classList.remove('open');
        this.mobileHeader.classList.remove('open');
        this.bodyElement.style.overflow = 'auto';
        
        this.updateState('closed');
    }
}

function MobileMenu() {

    let topLevelMenu = $qs('.js-mobile-menu');
    let menuTriggerButton = $qs('.js-menu-trigger');

    if (topLevelMenu && menuTriggerButton) {
        let mobileMenu = new Menu(topLevelMenu, menuTriggerButton);
    }

}

export default MobileMenu;