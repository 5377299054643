import 'babel-polyfill';

// Components
import Sliders from './components/Sliders';
import BrandsGrid from './components/BrandsGrid';
import LeadershipSlider from './components/LeadershipSlider';
// import Map from './components/Map';
import Tabs from './components/Tabs';
import MobileMenu from './components/MobileMenu';
import Menu from './components/Menu';

// Animations
import Preloader from './animations/Preloader';



// Vanilla JS
function init() {
	// Components
	Sliders();
	BrandsGrid();
	LeadershipSlider();
	// Map();
	Tabs();
	MobileMenu();
	Menu();

	// Animations
	Preloader();
}

init();



jQuery(document).ready(function ($) {
    consoleMessage: {
        console.log('%cCreated by %cTWK%cwww.thewebkitchen.co.uk', 'background: #13212E; color: #FFFFFF; padding: 5px 0px 5px 10px; margin: 25px 0px;', 'background: #13212E; color: #05E5C8; padding: 5px 10px 5px 0px; font-weight: bold;', 'background: #FFFFFF; padding: 5px 10px;');
	}
	modernizer: {
        if (!Modernizr.objectfit) {
            $('.object-fit').each(function () {
                var $container = $(this),
                    imgUrl = $container.find('img').prop('src');
                if (imgUrl) {
                    $container
                        .css('backgroundImage', 'url(' + imgUrl + ')')
                        .addClass('not-compatible');
                }
            });
        }
	}
	anchorlinks: {
		$(document).on('click', '.scroll-to', function (event) {
			event.preventDefault();

			$('html, body').animate({
				scrollTop: $($.attr(this, 'href')).offset().top - 30
			}, 500);
		});
	}
	externallinks: {
		$('a[href^="mailto:"]').each(function () {
			$(this).addClass('email-link');
		});	 
		
		$('a:not(.email-link):not([class^="magnific-"])').each(function () {
			var a = new RegExp('/' + window.location.host + '/');
			
			if (!a.test(this.href)) {
				$(this).click(function (event) {
				event.preventDefault();
				window.open(this.href, '_blank');
				});
			}
		});
		pdfs: {
			// Open PDFs in new window
			$(function () {
				$('a[href$=".pdf"]').prop('target', '_blank');
			});
		}
	}
	magnific: {
		// IFRAME
		$('.magnific-video').magnificPopup({
			type: 'iframe',
			mainClass: 'mfp-video-wrapper',
			removalDelay: 160,
			preloader: false,
			fixedContentPos: false,

			iframe: {
				markup: '<div class="mfp-iframe-scaler">' +
					'<div class="mfp-close"></div>' +
					'<iframe class="mfp-iframe" frameborder="0" allow="autoplay"></iframe>' +
					'</div>',
				patterns: {
					youtu: {
						index: 'youtu.be',
						id: function( url ) {
						
							// Capture everything after the hostname, excluding possible querystrings.
							var m = url.match( /^.+youtu.be\/([^?]+)/ );
					
							if ( null !== m ) {
								return m[1];
							}
					
							return null;
				
						},
						// Use the captured video ID in an embed URL. 
						// Add/remove querystrings as desired.
						src: '//www.youtube.com/embed/%id%?autoplay=1&rel=0'
					},
					youtube: {
						index: 'youtube.com/',
						id: 'v=',
						src: 'https://www.youtube.com/embed/%id%?autoplay=1'
					}
				}
			}
		});
	}
    checkFormSubmitted: {
        $(document).on('gform_confirmation_loaded', function(event, formId) {
            window.localStorage.setItem('webform_submited', 'true');
            digitalData.article_data.webform_submited = true;
        });
    }
});