import { $qs, $qsa } from '../utils/QuerySelector';
import Flickity from 'flickity';
import 'flickity-sync';
import 'flickity-fade';
import { media } from '../utils/MediaQueries';



function brandsSlider() {
    const sliderParent = $qs('section.banner--home');

    if (!sliderParent) return;
    
    const sliderNode = $qs('.js-slider', sliderParent);
    
    const flickity = new Flickity(sliderNode, {
        autoPlay: 1500,
        prevNextButtons: false,
        pageDots: false,
        draggable: true,
        wrapAround: true,
        freeScroll: true,
        pauseAutoPlayOnHover: false,
        // setGallerySize: false,
        selectedAttraction: 0.03,
        // friction: 0.25,
        on: {
            ready: () => sliderNode.classList.add('flickity-ready')
        }
    });
}

function statisticSlider() {
    const sliderParent = $qs('section.statistic-slider');

    if (!sliderParent) return;
    
    const sliderNode = $qs('.js-slider', sliderParent);
    const prevButtons = $qsa('.js-slider-prev', sliderParent);
    const nextButtons = $qsa('.js-slider-next', sliderParent);
    
    const flickity = new Flickity(sliderNode, {
        autoPlay: false,
        prevNextButtons: false,
        pageDots: false,
        draggable: true,
        wrapAround: true,
        freeScroll: true,
        // setGallerySize: false,
        // selectedAttraction: 0.015,
        // friction: 0.25,
        on: {
            ready: () => sliderNode.classList.add('flickity-ready')
        }
    });

    prevButtons && [...prevButtons].forEach(prevButton => prevButton.addEventListener('click', () => flickity.previous(), false));
    nextButtons && [...nextButtons].forEach(nextButton => nextButton.addEventListener('click', () => flickity.next(), false));
}

function capabilitiesSlider() {
    const sliderParent = $qs('section.tab-slider');

    if (!sliderParent) return;
    
    const contentSliderNode = $qs('.js-slider-content', sliderParent);
    const imageSliderNode = $qs('.js-slider-images', sliderParent);
    const prevButton = $qs('.js-slider-prev', sliderParent);
    const nextButton = $qs('.js-slider-next', sliderParent);
    const currentNumber = $qs('.js-slider-numbers span:nth-of-type(1)', sliderParent);
    const maxNumber = $qs('.js-slider-numbers span:nth-of-type(2)', sliderParent);
    const slides = $qsa('.slider__slide', contentSliderNode);
    const slidesCount = slides.length;
    
    const contentSlider = new Flickity(contentSliderNode, {
        autoPlay: false,
        prevNextButtons: false,
        pageDots: false,
        draggable: true,
        wrapAround: true,
        freeScroll: false,
        sync: imageSliderNode,
        fade: true,
        // adaptiveHeight: true,
        on: {
            ready: () => {
                contentSliderNode.classList.add('flickity-ready')
                if(slidesCount < 10){
                maxNumber.innerText = `0${slidesCount}`;
                }else{
                    maxNumber.innerText = `${slidesCount}`;
                }
            },
            change: index => {
                if(slidesCount < 10){
                    currentNumber.innerText = `0${index + 1}`;
                    }else{
                        currentNumber.innerText = `${index + 1}`;
                    }
            },
        }
    });

    const imagesSlider = new Flickity(imageSliderNode, {
        autoPlay: false,
        prevNextButtons: false,
        pageDots: false,
        draggable: true,
        wrapAround: true,
        freeScroll: false,
        on: {
            ready: () => imageSliderNode.classList.add('flickity-ready')
        }
    });

    prevButton.addEventListener('click', () => {
        contentSlider.previous()
    
        // if (media.md) {
        //     $('html, body').animate({
        //         scrollTop: $(sliderParent).offset().top
        //     }, 500);
        // }
    }, false);
    nextButton.addEventListener('click', () => {
        contentSlider.next()
        
        // if (media.md) {
        //     $('html, body').animate({
        //         scrollTop: $(sliderParent).offset().top
        //     }, 500);
        // }
    }, false);

    // banner tabs
    const bannerTabs = [...$qsa('.banner--what-we-do .banner__tab')];

    bannerTabs.forEach(bannerTab => {
        bannerTab.addEventListener('click', () => {
            const { slide } = bannerTab.dataset;

            contentSlider.select(slide);
            bannerTabs.forEach(bannerTab => bannerTab.classList.remove('active'))
            bannerTab.classList.add('active');

            $('html, body').animate({
                scrollTop: $(sliderParent).offset().top - 50
            }, 500);
        });
    });
}

function imageSlider() {
    const sliderParent = $qs('section.banner--join-us');

    if (!sliderParent) return;
    
    const sliderNode = $qs('.js-slider', sliderParent);
    
    const flickity = new Flickity(sliderNode, {
        autoPlay: true,
        prevNextButtons: false,
        pageDots: false,
        draggable: true,
        wrapAround: true,
        freeScroll: true,
        pauseAutoPlayOnHover: false,
        on: {
            ready: () => sliderNode.classList.add('flickity-ready')
        }
    });
}

function leadershipSlider() {
    const sliderParent = $qs('section.leadership-slider');

    if (!sliderParent) return;
    
    const sliderNode = $qs('.js-slider', sliderParent);
    const prevButton = $qs('.js-slider-prev', sliderParent);
    const nextButton = $qs('.js-slider-next', sliderParent);

    window.draggingSlider = false;
    
    const flickity = new Flickity(sliderNode, {
        autoPlay: false,
        prevNextButtons: false,
        pageDots: false,
        draggable: true,
        wrapAround: true,
        freeScroll: true,
        cellAlign: 'left',
        on: {
            ready: () => sliderNode.classList.add('flickity-ready'),
            // pointerDown: () => window.draggingSlider = true,
            // pointerUp: () => window.draggingSlider = false
        }
    });

    prevButton.addEventListener('click', () => flickity.previous(), false);
    nextButton.addEventListener('click', () => flickity.next(), false);
}

function officesSlider() {
    const sliderParent = $qs('section.banner--contact');

    if (!sliderParent) return;
    
    const sliderNode = $qs('.js-slider', sliderParent);
    
    const flickity = new Flickity(sliderNode, {
        autoPlay: false,
        prevNextButtons: false,
        pageDots: false,
        draggable: false,
        wrapAround: true,
        fade: true,
        on: {
            ready: () => sliderNode.classList.add('flickity-ready')
        }
    });

    // banner tabs
    const bannerTabs = [...$qsa('.banner__tab', sliderParent)];

    bannerTabs.forEach(bannerTab => {
        bannerTab.addEventListener('click', () => {
            const { slide } = bannerTab.dataset;

            flickity.select(slide);
            bannerTabs.forEach(bannerTab => bannerTab.classList.remove('active'))
            bannerTab.classList.add('active');
        });
    });
}

function timelineSlider() {
    const sliderParent = $qs('section.timeline');

    if (!sliderParent) return;
    
    const sliderNode = $qs('.js-slider', sliderParent);
    const prevButton = $qs('.js-slider-prev', sliderParent);
    const nextButton = $qs('.js-slider-next', sliderParent);
    const contentButton = $qs('.js-content-button', sliderParent);
    
    const flickity = new Flickity(sliderNode, {
        autoPlay: false,
        prevNextButtons: false,
        pageDots: false,
        draggable: true,
        wrapAround: false,
        freeScroll: true,
        cellAlign: 'left',
        contain: true,
        on: {
            ready: () => sliderNode.classList.add('flickity-ready')
        }
    });

    prevButton.addEventListener('click', () => flickity.previous(), false);
    nextButton.addEventListener('click', () => flickity.next(), false);
    contentButton.addEventListener('click', () => flickity.next(), false);
}

function eventsSlider() {
    const sliderParent = $qs('section.events-slider');

    if (!sliderParent) return;
    
    const sliderNode = $qs('.js-slider', sliderParent);
    const prevButtons = $qsa('.js-slider-prev', sliderParent);
    const nextButtons = $qsa('.js-slider-next', sliderParent);
    const currentNumber = $qs('.js-slider-numbers span:nth-of-type(1)', sliderParent);
    const maxNumber = $qs('.js-slider-numbers span:nth-of-type(2)', sliderParent);
    const slides = $qsa('.slider__slide', sliderParent);
    const slidesCount = slides.length;
    
    const flickity = new Flickity(sliderNode, {
        autoPlay: false,
        prevNextButtons: false,
        pageDots: false,
        draggable: true,
        wrapAround: true,
        freeScroll: false,
        // cellAlign: 'center',
        // setGallerySize: false,
        // selectedAttraction: 0.015,
        // friction: 0.25,
        on: {
            ready: () => {
                sliderNode.classList.add('flickity-ready')
                if(slidesCount < 10){
                maxNumber.innerText = `0${slidesCount}`;
                }else{
                    maxNumber.innerText = `${slidesCount}`;
                }
            },
            change: index => {
                if(slidesCount < 10){
                    currentNumber.innerText = `0${index + 1}`;
                    }else{
                        currentNumber.innerText = `${index + 1}`;
                    }
            },
            dragStart: () => {
                sliderNode.style.pointerEvents = 'none';
            },
            dragEnd: () => {
                sliderNode.style.pointerEvents = 'auto';
            }
        }
    });

    prevButtons && [...prevButtons].forEach(prevButton => prevButton.addEventListener('click', () => flickity.previous(), false));
    nextButtons && [...nextButtons].forEach(nextButton => nextButton.addEventListener('click', () => flickity.next(), false));
}

function Sliders() {
    statisticSlider();
    brandsSlider();
    capabilitiesSlider();
    imageSlider();
    // leadershipSlider();
    officesSlider();
    timelineSlider();
    eventsSlider();

    // $(document).on('sf:ajaxfinish', '.searchandfilter#search-filter-form-642', leadershipSlider);
}

export default Sliders;