import { $qs, $qsa } from '../utils/QuerySelector';



function detectMenuItemClick() {
    // for adobe analytics digitalData

    const menuItems = $qsa('#menu-main .menu-item');

    menuItems && [...menuItems].forEach((item) => {
        item.addEventListener('click', (e) => {
            const link = e.target.closest('a');
            const linkText = link.innerText;
            const linkHref = link.getAttribute('href');

            window.localStorage.setItem('menu_click', linkText);
        });
    });
}

function Menu() {
    detectMenuItemClick();
}

export default Menu;