import { $qs, $qsa } from "../utils/QuerySelector";
import { media } from "../utils/MediaQueries";
import Flickity from 'flickity';
import 'flickity-sync';
import 'flickity-fade';



async function fetchleadershipMemberData(e, cell) {
    try {
        const body = $qs('body');
        const { id } = cell.dataset;
        const destination = $qs('.js-leadership-container');
        const data = new FormData();

        data.append('action', 'fetch_leadership_member');
        data.append('post_id', id);

        const params = new URLSearchParams(data);

        const response = await fetch(php_vars.ajaxurl, {
            method: 'POST',
            credentials: 'same-origin',
            headers: {
                // 'Content-Type': 'application/x-www-form-urlencoded',
                // 'Cache-Control': 'no-cache',
            },
            body: params
        });

        const html = await response.text();
        
        $(destination).append(html);

        destination.style.userSelect = 'auto';
        
        const popup = $qs('.popup--leadership-member');
        const popupClose = $qs('.js-popup-close', popup);

        if (media.md) body.style.overflow = 'hidden';

        // fade in
        setTimeout(() => popup.classList.add('visible'), 100);

        popupClose.addEventListener('click', () => {
            popup.classList.remove('visible');
            destination.style.userSelect = 'none';
            
            if (media.md) body.style.overflow = 'visible';

            setTimeout(() => {
                $(popup).remove();
            }, 200);
        });

    } catch (error) {
        console.log(error);
    }
}

function setUpSlider() {
    const sliderParent = $qs('section.leadership-slider');

    if (!sliderParent) return;
    
    const sliderNode = $qs('.js-slider', sliderParent);
    const prevButton = $qs('.js-slider-prev', sliderParent);
    const nextButton = $qs('.js-slider-next', sliderParent);

    window.draggingSlider = false;
    
    const flickity = new Flickity(sliderNode, {
        autoPlay: false,
        prevNextButtons: false,
        pageDots: false,
        draggable: true,
        wrapAround: true,
        freeScroll: true,
        cellAlign: 'left',
        lazyLoad: false,
        on: {
            ready: () => sliderNode.classList.add('flickity-ready'),
            staticClick: (event, pointer, cellElement, cellIndex) => {
                cellElement && fetchleadershipMemberData(event, $qs('.teaser', cellElement));
            }
        }
    });

    prevButton.addEventListener('click', () => flickity.previous(), false);
    nextButton.addEventListener('click', () => flickity.next(), false);
}

function LeadershipSlider() {
    if (!$qs('body.page-template-tpl-who-we-are')) return;

    setUpSlider();

    $(document).on('sf:ajaxfinish', '.searchandfilter', setUpSlider);
}

export default LeadershipSlider;