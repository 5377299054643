import { $qs, $qsa } from '../utils/QuerySelector'
import gsap from 'gsap'
import CustomEase from 'gsap/CustomEase'
gsap.registerPlugin(CustomEase)

function setLocalStorageWithExpiry(key, value, ttl) {
  var now = new Date()

  // `item` is an object which contains the original value
  // as well as the time when it's supposed to expire
  var item = {
    value: value,
    expiry: now.getTime() + ttl,
  }
  localStorage.setItem(key, JSON.stringify(item))
}

function getLocalStorageWithExpiry(key) {
  var itemStr = localStorage.getItem(key)

  // if the item doesn't exist, return null
  if (!itemStr) {
    return null
  }

  var item = JSON.parse(itemStr)
  var now = new Date()

  // compare the expiry time of the item with the current time
  if (now.getTime() > item.expiry) {
    // If the item is expired, delete the item from storage
    // and return null
    localStorage.removeItem(key)
    return null
  }

  return true
}

function Preloader() {
  const preloader = $qs('.js-preloader')

  if (!preloader) return

  const body = $qs('body')
  const sections = [...$qsa('.preloader__section', preloader)]
  const topNav = $qs('.top-nav')
  const banner = $qs('.banner')
  const bannerContainer = $qs('.banner__container')
  const bannerImageWrapper = $qs('.banner__image-wrapper')
  const bannerBackground = $qs('.banner__background')

  const preloaderTimeline = gsap.timeline({
    // paused: true,
    // delay: 0.5,
    onComplete: () => {
      preloader.style.display = 'none'
      body.style.overflow = 'auto'
    },
  })

  CustomEase.create('preloaderEase', 'M0,0,C0.114,0.61,0.14,1,1,1')
  const customEase = 'preloaderEase'

  window.addEventListener('visibilitychange', () => window.scrollTo(0, 0))
  body.style.overflow = 'hidden'

  gsap.set(topNav, { opacity: 0 })
  gsap.set(bannerContainer, { opacity: 0 })
  gsap.set(bannerImageWrapper, { clipPath: `inset(0% 50%)` })
  gsap.set(bannerBackground, { opacity: 0 })

  preloaderTimeline.addLabel('start')

  sections.forEach((section, i) => {
    const background =
      i === sections.length - 1
        ? $qs('.banner__background')
        : $qs('.preloader__background', section)
    const word = $qs('.preloader__word', section)

    preloaderTimeline.addLabel(`section_${i}`, '+=1')

    if (i !== sections.length - 1) {
      preloaderTimeline.to(
        background,
        {
          opacity: 0,
          duration: 0.5,
          ease: 'none',
        },
        `section_${i}`
      )
    }

    preloaderTimeline.to(
      word,
      {
        opacity: 0,
        duration: i === sections.length - 1 ? 0.5 : 0,
        ease: 'none',
      },
      `section_${i}`
    )

    if (i !== 0) {
      preloaderTimeline.to(
        background,
        {
          opacity: 1,
          duration: 0.5,
          ease: 'none',
        },
        `section_${i}-=1.5`
      )
      preloaderTimeline.to(
        word,
        {
          opacity: 1,
          duration: 0,
          ease: 'none',
        },
        `section_${i}-=1.5`
      )
    }
  })

  preloaderTimeline
    .set(bannerContainer, {
      opacity: 1,
    })
    .to(
      bannerImageWrapper,
      {
        clipPath: `inset(0% 0%)`,
        duration: 1.5,
        ease: 'power2.inOut',
      },
      '+=0.1'
    )
    .to(
      topNav,
      {
        opacity: 1,
        duration: 1,
        ease: 'power3.inOut',
      },
      '-=0.5'
    )

  // Check if localStorage variable set to determine whether to show the preloader or not
  if (getLocalStorageWithExpiry('preloader_viewed')) {
    preloaderTimeline.progress(1)
  } else {
    setLocalStorageWithExpiry('preloader_viewed', 'true', 1000 * 60 * 60 * 24 * 7);
    preloaderTimeline.play()

    setTimeout(() => {
      setLocalStorageWithExpiry('preloader_viewed', 'true', 1000 * 60 * 60 * 24 * 7);
    }, 2000)
  }
}

export default Preloader
