import { $qs, $qsa } from "../utils/QuerySelector";
import { media } from "../utils/MediaQueries";
import Tabs from './Tabs';



async function fetchBrandData() {
    try {
        const body = $qs('body');
        const { id } = this.dataset;
        const destination = $qs('.js-brands-container');
        const data = new FormData();

        data.append('action', 'fetch_brand');
        data.append('post_id', id);

        const params = new URLSearchParams(data);

        const response = await fetch(php_vars.ajaxurl, {
            method: 'POST',
            credentials: 'same-origin',
            headers: {
                // 'Content-Type': 'application/x-www-form-urlencoded',
                // 'Cache-Control': 'no-cache',
            },
            body: params
        });

        const html = await response.text();
        
        $(destination).append(html);

        Tabs();
        
        const popup = $qs('.popup--brands-grid');
        const popupCloses = popup && $qsa('.js-popup-close', popup);

        if (media.md) body.style.overflow = 'hidden';

        // fade in
        setTimeout(() => popup.classList.add('visible'), 100);

        popupCloses && [...popupCloses].forEach(popupClose => {
            popupClose.addEventListener('click', () => {
                popup.classList.remove('visible');
                if (media.md) body.style.overflow = 'visible';
    
                setTimeout(() => {
                    $(popup).remove();
                }, 200);
            });
        });

    } catch (error) {
        console.error('Fetch error:', error);
    }
}

function BrandsGrid () {
    if (!$qs('body.page-template-tpl-what-we-do')) return;

    const section = $qs('section.brands-grid');
    const brands = $qsa('.brand', section);

    brands && [...brands].forEach(brand => brand.addEventListener('click', fetchBrandData));

    $(document).on('sf:ajaxfinish', '.searchandfilter', function() {
        const section = $qs('section.brands-grid');
        const brands = $qsa('.brand', section);

        brands && [...brands].forEach(brand => brand.addEventListener('click', fetchBrandData));
    });
}

export default BrandsGrid;